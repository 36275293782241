export const ONLY_TUUN_COUNTRIES = [
	'BD', 'BR', 'CL', 'CD', 'EC', 'GH', 'IN', 'ID', 'KE', 'MQ', 'MU', 'MA', 'NE', 'PE', 'TW', 'TR', 'UG', 'UY', 'ZM', 'NG', 'PH', 'CR', 'GT', 'SV', 'AR', 'KH', 'MO', 'TH',
];

export const PRODUCT_INFO_MESSAGE = {
	countries: ['US', 'CA', 'MX'],
	messages: {
		default: 'products_note',
	},
};

export const HIDE_CUSTOM_ORGANIZATION_COUNTRIES = [];

export const PROMO_UPGRADE_PACKAGES = {
	apprentice: 'apprentice',
	promoter: 'promoter',
	influencer: 'influencer',
	ambassador: 'ambassador',
	ambassador_plus: 'ambassador_plus',
};

export const REASSIGN_PACKAGES = {
	influencer_custom_bonus_trip_points: 'influencer',
	influencer_fixed_bonus_trip_points: 'influencer',
	ambassador_custom_bonus_trip_points: 'ambassador',
	ambassador_fixed_bonus_trip_points: 'ambassador',
	influencer_fixed_reserve_bonus_trip_points_jp: 'influencer',
	influencer_fixed_mind_bonus_trip_points_jp: 'influencer',
	influencer_fixed_am_pm_bonus_trip_points_jp: 'influencer',
	ambassador_fixed_reserve_bonus_trip_points_jp: 'ambassador',
	ambassador_fixed_mind_bonus_trip_points_jp: 'ambassador',
	ambassador_fixed_am_pm_bonus_trip_points_jp: 'ambassador',
};

export const CONFIRMATION_PACKAGE_MESSAGE = [];

// Packages that should not update name
export const NON_SPECIAL_PACKAGES = [];

export const PACKAGE_NAME_BY_FLOW = {
	register: 'enrollment',
	purchase: 'member',
};

export const REPLICATED_LEGS_LINK = ['signup-left', 'signup-right'];

export const MEMBERSHIP_PRICE = 49.95;

export default {};
